import React from 'react'
import Layout from '../components/old/Layout';

export default class Projects extends React.Component {
    render() {
        return (
            <Layout tab="projects">
                These are some of my projects.
            </Layout>
        )
    }
}